import revive_payload_client_YTGlmQWWT5 from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/home/ploi/dev.wijsneus.app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ploi/dev.wijsneus.app/apps/app.wijsneus.web/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/ploi/dev.wijsneus.app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vR2DTZAOp5 from "/home/ploi/dev.wijsneus.app/node_modules/nuxt-auth-sanctum/dist/runtime/plugin.js";
import sentry_client_AGfZKS8NAa from "/home/ploi/dev.wijsneus.app/plugins/sentry.client.ts";
import firebase_client_8FdsRHb16W from "/home/ploi/dev.wijsneus.app/plugins/firebase.client.ts";
import deepLinks_lWvrP8tYbv from "/home/ploi/dev.wijsneus.app/plugins/deepLinks.ts";
import fetchAssistantSettings_budENjfD64 from "/home/ploi/dev.wijsneus.app/plugins/fetchAssistantSettings.ts";
import hideSplashScreen_mETFSsNevX from "/home/ploi/dev.wijsneus.app/plugins/hideSplashScreen.ts";
import onLogin_rOlEENYm8G from "/home/ploi/dev.wijsneus.app/plugins/onLogin.ts";
import onLogout_zRrJWG55fd from "/home/ploi/dev.wijsneus.app/plugins/onLogout.ts";
import setOverlayState_AUMoCaRfp8 from "/home/ploi/dev.wijsneus.app/plugins/setOverlayState.ts";
import safeTeleport_8rNpYvAi7P from "/home/ploi/dev.wijsneus.app/plugins/safeTeleport.ts";
import toastify_client_UGUq3HJYob from "/home/ploi/dev.wijsneus.app/plugins/toastify.client.ts";
import vue_final_modal_client_46vZVexFUg from "/home/ploi/dev.wijsneus.app/plugins/vue-final-modal.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_vR2DTZAOp5,
  sentry_client_AGfZKS8NAa,
  firebase_client_8FdsRHb16W,
  deepLinks_lWvrP8tYbv,
  fetchAssistantSettings_budENjfD64,
  hideSplashScreen_mETFSsNevX,
  onLogin_rOlEENYm8G,
  onLogout_zRrJWG55fd,
  setOverlayState_AUMoCaRfp8,
  safeTeleport_8rNpYvAi7P,
  toastify_client_UGUq3HJYob,
  vue_final_modal_client_46vZVexFUg
]